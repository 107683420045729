import { Box, Heading } from "@chakra-ui/react"
import * as React from "react"
import { getSessionStats } from "../../utilities/sessions"
import { metersToFeet } from "../../utilities/measure"
import { NumericFormat } from "react-number-format"
import useColors from "src/app/common/hooks/useColors"

const Stat = ({ value, label, unit = null, hasBorder = false }) => {
  return (
    <Box
      display="flex"
      borderColor={"brand.whiteColor"}
      borderLeftWidth={hasBorder ? 1 : 0}
      pl={2}
      flex={1}
      minWidth={"80px"}
      alignItems="center"
      justifyContent="center"
      my={2}
      pr={2}
      flexDirection="column"
    >
      <Heading as="h2" size="lg" mb={0} color="brand.orangeColor">
        {value}
        {!!unit && (
          <Heading as="h3" size="sm" color="brand.orangeColor">
            {unit}
          </Heading>
        )}
      </Heading>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Heading mx={1} as="h6" fontSize={10} textAlign="center">
          {label}
        </Heading>
      </Box>
    </Box>
  )
}

export interface ISessionStatsProps {
  session: any
  isInFeed?: boolean
  isCurrent?: boolean
  gradeType?: string
}

export default function SessionStats ({
  session,
  isInFeed = false,
  isCurrent = false,
  gradeType = "us",
}: ISessionStatsProps) {
  const {
    totalTimeStr,
    pebbleIntensity,
    restTimeStr,
    pebblePoints,
    sendsAndAttempts = 0,
  } = getSessionStats(session)
  const { insetColor, whiteColor } = useColors()

  const totSends = session.sends.length

  // @ts-ignore
  if (sendsAndAttempts?.length === 1) {
    return (
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Heading size="md" as="h2" textAlign="center">
          Sorry, there is not enough data to accurately give any stats.
        </Heading>
      </Box>
    )
  }

  return (
    <Box height={"100%"} display="flex" alignItems={"center"}>
      <Box
        position={"relative"}
        flexWrap="wrap"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flex={1}
        mt={8}
        px={2}
      >
        <Box
          position="absolute"
          top="50%"
          left={session.sessionType === "workout" ? "50%" : "33%"}
          right={0}
          bottom={0}
          bg={isInFeed || isCurrent ? insetColor : whiteColor}
          height={"16px"}
          width={"16px"}
          ml={"-7px"}
          mt={"-11px"}
          zIndex={1}
        />
        {session.sessionType !== "workout" && (
          <Box
            position="absolute"
            top="50%"
            right="33%"
            bottom={0}
            bg={isInFeed || isCurrent ? insetColor : whiteColor}
            height={"16px"}
            width={"16px"}
            mt={"-11px"}
            mr={"-7px"}
            zIndex={1}
          />
        )}
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-around"
        >
          <Stat value={`${totalTimeStr}`} label="Duration (h:m)" />
          <Stat
            hasBorder
            value={
              <NumericFormat
                displayType="text"
                thousandSeparator={true}
                value={pebbleIntensity}
              />
            }
            label="Workload"
          />
          {session.sessionType !== "workout" && (
            <Stat hasBorder value={restTimeStr} label="Avg. Rest (m:s)" />
          )}
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          mb={2}
          borderTopWidth={1}
          borderTopColor={"brand.whiteColor"}
        >
          {session.sessionType === "workout" ? (
            <Stat value={restTimeStr} label="Avg. Rest (m:s)" />
          ) : (
            <Stat
              // @ts-ignore
              value={`${totSends} / ${sendsAndAttempts.length}`}
              label={`Sends / Tries`}
            />
          )}
          <Stat
            hasBorder
            value={
              <NumericFormat
                displayType="text"
                thousandSeparator={true}
                value={pebblePoints}
              />
            }
            label="Grade Points"
          />
          {!!sendsAndAttempts && (
            <Stat
              hasBorder
              value={
                <NumericFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={Math.round(
                    gradeType === "us"
                      ? metersToFeet(session.totalMeters)
                      : session.totalMeters,
                  )}
                />
              }
              label={gradeType === "us" ? "Feet Climbed" : "MetersClimbed"}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
